<template>
  <v-container>
    <v-card
      class="mx-auto mb-5"
      max-width="1200px"
      v-for="(item, index) in items"
      :key="index"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <b>Author:</b> {{ item.author }}
          </v-list-item-title>
          <v-list-item-title>
            <b>Presented at:</b> {{ item.presentedAt }}
          </v-list-item-title>
          <v-list-item-title v-if="item.location">
            <b>Location:</b> {{ item.location }}
          </v-list-item-title>
          <v-list-item-title> <b>Date:</b> {{ item.date }} </v-list-item-title>
          <v-list-item-title v-if="item.awards">
            <b>Awards:</b> {{ item.awards }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          title:
            "VIGOR: Virtual Interaction with Gravitational Waves to Observe Relativity (Paper)",
          author:
            "Midori Kitagawa, Michael Kesden, Ngoc Tran, Thulasi Sivampillai Venlayudam, Mary Urquhart, and Roger Malina",
          presentedAt:
            "Proceedings of the 9th International Conference on Virtual, Augmented and Mixed Reality (VAMR 2017)",
          location: "Vancouver, BC, Canada",
          date: "Jul. 2017",
        },
        {
          title:
            "Virtual Interaction with Gravitational Waves to Observe Relativity (Poster)",
          author:
            "Monisha Elumalai, Midori Kitagawa, Michael Kesden, Ngoc Tran, Thulasi Sivampillai Venlayudam, Mary Urquhart and Roger Malina",
          presentedAt:
            "Fall 2017 Joint Meeting of the Texas Section of the American Physical Society, the Texas Section of the American Association of Physics Teachers, and Zone 13 of the Society of Physics Students",
          location: "Richardson, Texas",
          date: "2017",
        },
        {
          title:
            "Simulation of Gravitational Waves to Observe Relativity (Master’s Thesis)",
          author: "Monisha Elumalai",
          presentedAt: "University of Texas at Dallas",
          location: "Richardson, Texas",
          date: "2018",
        },
        {
          title:
            "Virtual Interaction with Gravitational Waves to Observe Relativity (Demonstration)",
          author:
            "Monisha Elumalai, Midori Kitagawa, Michael Kesden, Mary Urquhart and Roger Malina",
          presentedAt:
            "IWISC 2018: 3rd International Workshop on Interactive and Spatial Computing",
          location: "Richardson, Texas",
          date: "2018",
          awards: "Best Demonstration Award",
        },

        {
          title:
            "Leveraging UTeach Dallas Outreach to Test Interactive Unity-Based Simulations for Physics Education (Poster)",
          author: "Mary Urquhart, Midori Kitagawa, and Michael Kesden",
          presentedAt: "12th Annual UTeach Conference",
          location: "Austin, Texas",
          date: "2018",
        },
        {
          title:
            "Virtual Interaction with Gravitational Waves to Observe Relativity (VIGOR) (Abstract & Workshop)",
          author: "Michael Kesden, Midori Kitagawa, and Mary Urquhart",
          presentedAt: "2018 Conference on the Advancement of Science Teaching",
          location: "Fort Worth, Texas",
          date: "2018",
        },
        {
          title:
            "Virtual Interaction with Gravitational Waves to Observe Relativity (VIGOR) (Presentation)",
          author: "Michael Kesden, Midori Kitagawa, and Mary Urquhart",
          presentedAt:
            "Education and Outreach Breakout Session, 2019 Building Astronomy in Texas Symposium",
          location: "Richardson, Texas",
          date: "2019",
        },
        {
          title:
            "Virtual Interaction with Gravitational Waves to Observe Relativity (VIGOR) (Abstract & Workshop)",
          author: "Michael Kesden, Midori Kitagawa, and Mary Urquhart",
          presentedAt: "2019 Metroplex MiniCAST",
          location: "Allen, Texas",
          date: "2019",
        },
        {
          title: "Interact Virtually with Gravitational Waves Using VIGOR",
          author: "Mary Urquhart, Michael Kesden, and Midori Kitagawa",
          presentedAt:
            "Astronomical Society of the Pacific 2020 International Conference, Embracing the Future: Astronomy Teaching and Public Engagement. (Virtual)",
          date: "2020",
        },
      ],
    };
  },
};
</script>
