<template>
  <iframe
    :src="`${publicPath}unity.html`"
    frameborder="0"
    style="min-width: 100% !important; min-height: 100% !important"
  >
  </iframe>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
