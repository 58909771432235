import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Team from "../views/Team.vue";
import Publication from "../views/Publication.vue";
import Manual from "../views/Manual.vue";
import Application from "../views/Application.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/team",
    name: "team",
    component: Team,
  },
  {
    path: "/publications",
    name: "publications",
    component: Publication,
  },
  {
    path: "/manual",
    name: "manual",
    component: Manual,
  },
  {
    path: "/application",
    name: "application",
    component: Application,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
