<template>
  <v-container>
    <v-card class="mx-auto mb-5" max-width="1200px">
      <v-card-text>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline"> Manual </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-row class="fill-height mb-4" align="center" justify="center">
          <v-img :src="`${publicPath}vigor_screen_cap.jpg`" max-width="600px">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-row>

        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr justify="center">
                <td><b>Parameters</b></td>
                <td>
                  <p class="ma-2">
                    <b>Exaggeration Factor</b> – scales the GW amplitude for
                    easy visualization <br />
                    <b>Total Mass</b> – the sum of the BH masses <br />
                    <b>Mass Ratio</b> – the mass of the smaller BH divided by
                    the mass of the bigger BH <br />
                    <b>Binary Separation</b> – the distance between the two BBHs
                  </p>
                </td>
              </tr>
              <tr>
                <td><b>Options & displays</b></td>
                <td>
                  <p class="ma-2">
                    <b>Back</b> – takes back to intro page <br />
                    <b>Distance</b> – the distance between the Earth and BBH
                    center of mass <br />
                    <b>Reset</b> – rests simulation to initial values <br />
                    <b>Radiation Reaction</b> – BBHs spiral inwards due to loss
                    of energy to GWs <br />
                    <b>Pause-Resume</b> – pause/unpause the simulation <br />
                    <b>Bull’s eye</b> – displays target to measure stretching
                    and squeezing <br />
                    <b>Pinwheel</b> – rests the stopwatch
                  </p>
                </td>
              </tr>
              <tr>
                <td><b>Navigation</b></td>
                <td>
                  <p class="ma-2">
                    <b>a & z keys</b> – change the viewing angle from edge on to
                    above/below orbital plane <br />
                    <b>left & right arrows</b> – rotate view about the axis of
                    BBH orbit <br />
                    <b>up & down arrows</b> – change the distance of the Earth
                    from BBH center of mass
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
