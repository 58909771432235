<template>
  <v-app-bar app dense flat color="secondary">
    <router-link to="/">
      <v-img
        :src="require('../assets/vigor_logo.png')"
        contain
        class="mx-3"
        max-height="35"
        max-width="35"
      >
        <template v-slot:placeholder>
          <v-row class="ma-1" align="center" justify="center">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </router-link>

    <v-toolbar-title class="headline text-uppercase">
      <router-link text to="/" style="text-decoration: none; color: inherit">
        <div class="d-inline">VIGOR</div>
      </router-link>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down">
      <v-tabs class="hidden-sm-and-down" hide-slider>
        <v-tab v-for="item in menu" :key="item.icon" :to="item.link">
          <v-icon size="17" left>mdi-{{ item.icon }}</v-icon>
          <div class="tab-width-2">
            {{ item.title }}
          </div>
        </v-tab>
      </v-tabs>
    </v-toolbar-items>

    <v-menu>
      <template v-slot:activator="{ on }">
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          v-on="on"
        ></v-app-bar-nav-icon>
      </template>

      <v-list rounded>
        <v-list-item v-for="item in menu" :key="item.icon" :to="item.link">
          <v-icon left size="18">mdi-{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          icon: "home",
          title: "Home",
          link: "/",
        },
        {
          icon: "account-multiple",
          title: "Team",
          link: "/team",
        },
        {
          icon: "newspaper-variant",
          title: "Publications",
          link: "/publications",
        },
        {
          icon: "notebook",
          title: "Manual",
          link: "/manual",
        },
        {
          icon: "play-circle",
          title: "Application",
          link: "/application",
        },
      ],
    };
  },
};
</script>

<style>
.v-tabs-bar {
  background-color: inherit !important;
}
</style>
